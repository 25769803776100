
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-dual-listbox {
  height: 15vw;
}
.rdl-move:last-child {
  height: 3vh;
}
.react-dual-listbox button, .react-dual-listbox select {
  height: 3vh;
  color: black;
}
.user-active {
  padding: 2px 10px;
  width: 57px;
  height: 20px;
  background: #D1FAE5;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.user-inactive {
  padding: 2px 10px;
  width: 66px;
  height: 20px;
  background: #FEE2E2;
  border-radius: 10px;
  color: #991B1B;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.vertical-line {
  border-left: 1px solid #dcdcdc;
  height: 20px;
  width: 20px;
  margin-right: 20px;
  margin-left: -10px;
}

.ant-slider .ant-slider-track {
  background-image: linear-gradient(to right, #1890ff,orange,red);
}

.editable-cell {
  position: relative;
}

.ant-select .ant-select-selector {
  border-radius:20px;
}